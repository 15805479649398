import React from 'react'
import shield from "../images/Icon shield.png"
import check from "../images/Icon check circle.png"
import bulb from "../images/Icon lightbulb.png"
import { Link } from 'react-router-dom'
function Confirmation() {
    return (
        <div className='bg-black h-[680px] pt-6 text-center justify-center items-center content-center self-center object-center'>
            <div className='flex text-center p-5 justify-center'>
                <img className='w-[61px] h-[33px]' alt='' src={shield}></img>
                <img className='w-[117px] h-[117px]' alt='' src={check}></img>
                <img className='w-[56px] h-[56px]' alt='' src={bulb}></img>
            </div>
            <div className='text-white font-Staatliches text-[38px] sm:text-[42px] mt-5 md:text-[48px]'>TWO THUMBS UP!</div>
            <div className='text-white font-Righteous mt-5 p-5 sm:text-[28px] text-[24px] md:text-[32px]'>We have your email and and will be in touch soon.</div>
            <Link to="/">
                <button type="button" className="text-white mt-28 bg-[#024d31] hover:bg-[#023824] focus:ring-2 focus:ring-[#023824] font-medium rounded-lg font-WorkSans text-sm  w-[265px] h-[48px] me-2 mb-10 text-[14px] ">Home</button>
            </Link>
        </div>
    )
}

export default Confirmation