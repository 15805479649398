import React from 'react'
import icon_email from "../images/Icon localpostoffice.png"
import icon_linkedin from "../images/Icon linkedin.png"
function footer() {
    return (
        <div className='bg-black text-white lg:pt-16 lg:p-8 p-6'>
            <div className='text-center'>
                <h1 className='p-2 font-WorkSans text-[#c0c0c0]'>We're social</h1>
                <div className='flex justify-center gap-4'>
                    <a className='hover:scale-125 hover:duration-500' href='??'><img src={icon_linkedin} alt="linkedin"></img></a>
                    <a className='hover:scale-125 hover:duration-500' href="??"><img src={icon_email} alt="email"></img></a>
                </div>
                <div className='w-full font-WorkSans h-[2px] mt-8 bg-[#393939]'></div>
                <h1 className='mt-8 mb-8 text-[#c0c0c0]'>Making in India, 2024</h1>
            </div>
        </div>
    )
}

export default footer